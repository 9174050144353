body {
    margin: 0;
    font-family: Arial, sans-serif;
  }
  
  .stock-table {
    margin: 20px auto;
    width: 90%;
  }
  
  .stock-table table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    font-size: 14px;
    text-align: center;
  }
  
  .stock-table th,
  .stock-table td {
    border: 1px solid #ddd;
    padding: 8px 12px;
  }
  
  .stock-table th {
    background-color: #f4f4f4;
    font-weight: bold;
    text-transform: uppercase;
  }
  
  .stock-table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .stock-table tr:hover {
    background-color: #f1f1f1;
  }
  
  .stock-table h1 {
    text-align: center;
    color: #333;
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .stock-table th[colspan="6"] {
    text-align: center;
  }
  
  .stock-table th[colspan="3"] {
    text-align: center;
  }  
  
.stock-table table {
    overflow-x: auto;
    display: block;
}