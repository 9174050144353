body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

.App {
  text-align: center;
  margin: 20px;
}

h1 {
  color: #333;
  margin-bottom: 20px;
}
